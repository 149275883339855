<template>
  <div class="mt-8 md:flex md:items-center md:justify-between pb-10">
    <!-- Text -->
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
        {{ text }}
      </h2>
    </div>
    <!-- Buttons -->
    <div
      v-if="$slots.buttons"
      class="flex mt-4 md:mt-0 md:ml-4"
    >
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewHeading',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>
