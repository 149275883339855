import { createApp } from 'vue'
import App from '@/App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Tailwind
import './index.css'

// AWS Amplify
import Amplify from 'aws-amplify'
import '@aws-amplify/ui-vue'
import awsmobile from './aws-exports'
Amplify.configure(awsmobile)

createApp(App).use(store).use(router).mount('#app')
