<template>
  <div class="home">
    <view-heading text="Home" />
    <div class="border-4 border-dashed border-gray-200 rounded-lg h-96 overflow-scroll" />
  </div>
</template>

<script>
import ViewHeading from '@/components/ViewHeading'

export default {
  name: 'HomeView',
  components: {
    ViewHeading
  }
}
</script>
