import { createRouter, createWebHistory } from 'vue-router'

import { Auth, Hub } from 'aws-amplify'

import store from '@/store/index.js'
import HomeView from '@/views/HomeView.vue'

// Routes

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../views/AccountView.vue'),
    meta: { signedIn: true }
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import(/* webpackChunkName: "sign-in" */ '../views/SignInView.vue'),
    meta: { signedOut: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/***********************************************************************************************************************
 ** Authentication Handler
 **********************************************************************************************************************/

// Set user object
let user

// Function to get currently logged in user
function getUser () {
  return Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUser', data)
      return data
    }
  }).catch(() => {
    store.commit('setUser', null)
    return null
  })
}

// Handle
router.beforeResolve(async (to, from, next) => {
  user = await getUser()
  if (to.matched.some(record => record.meta.signedIn)) {
    if (!user) {
      return next({
        path: '/sign-in'
      })
    }
    return next()
  }
  if (to.matched.some(record => record.meta.signedOut)) {
    if (user) {
      return next({
        path: '/'
      })
    }
    return next()
  }
  return next()
})

// Watch for authentication state changes and route accordingly
Hub.listen('auth', async (data) => {
  if (data.payload.event === 'signOut') {
    user = null
    store.commit('setUser', null)
    await router.push({ path: '/sign-in' })
  } else if (data.payload.event === 'signIn') {
    user = await getUser()
    await router.push({ path: '/account' })
  }
})

getUser()

export default router
