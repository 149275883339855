<template>
  <app-header />
  <main>
    <router-view />
  </main>
</template>

<script>
import AppHeader from '@/components/Header'

export default {
  name: 'App',
  components: {
    AppHeader
  }
}
</script>

<style lang="scss">
main {
  @apply max-w-7xl mx-auto sm:px-6 lg:px-8;
}
</style>
