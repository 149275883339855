<template>
  <disclosure
    v-slot="{ open }"
    as="nav"
    class="bg-white border-b border-gray-200"
  >
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <router-link
            to="/"
            class="flex-shrink-0 flex items-center"
          >
            <img
              class="block lg:hidden h-8 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            >
            <img
              class="hidden lg:block h-8 w-auto"
              src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
              alt="Workflow"
            >
          </router-link>
          <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              :class="[
                item.current ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
              ]"
            >
              {{ item.name }}
            </router-link>
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <!-- Notification Bell -->
          <!-- <button
            v-if="signedIn"
            type="button"
            class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">View notifications</span>
            <bell-icon
              class="h-6 w-6"
              aria-hidden="true"
            />
          </button> -->

          <!-- Profile dropdown -->
          <menu-wrapper
            v-if="signedIn"
            as="div"
            class="ml-3 relative"
          >
            <div>
              <menu-button class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">Open user menu</span>
                <div class="h-8 w-8 rounded-full bg-indigo-500" />
              </menu-button>
            </div>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <menu-items
                v-if="signedIn"
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <menu-item
                  v-for="item in userNavigation"
                  :key="item.name"
                  v-slot="{ active }"
                >
                  <router-link
                    :to="item.href"
                    :class="[
                      active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700'
                    ]"
                  >
                    {{ item.name }}
                  </router-link>
                </menu-item>
                <menu-item v-slot="{ active }">
                  <a
                    :class="[
                      active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700'
                    ]"
                    href="#"
                    @click="signOut"
                  >
                    Sign out
                  </a>
                </menu-item>
              </menu-items>
              <menu-items
                v-else
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <menu-item v-slot="{ active }">
                  <router-link
                    to="/sign-in"
                    :class="[
                      active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700'
                    ]"
                  >
                    Sign in
                  </router-link>
                </menu-item>
              </menu-items>
            </transition>
          </menu-wrapper>

          <router-link
            v-if="!signedIn"
            to="/sign-in"
            class="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sign in
          </router-link>
        </div>

        <!-- Mobile -->
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <disclosure-button class="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Open main menu</span>
            <MenuIcon
              v-if="!open"
              class="block h-6 w-6"
              aria-hidden="true"
            />
            <XIcon
              v-else
              class="block h-6 w-6"
              aria-hidden="true"
            />
          </disclosure-button>
        </div>
      </div>
    </div>

    <disclosure-panel class="sm:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <disclosure-button
          v-for="item in navigation"
          :key="item.name"
          as="router-link"
          :to="item.href"
          :class="[
            item.current ? 'bg-indigo-50 border-indigo-500 text-indigo-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
          ]"
        >
          {{ item.name }}
        </disclosure-button>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200">
        <div
          v-if="signedIn"
          class="flex items-center px-4"
        >
          <div class="flex-shrink-0">
            <div class="h-10 w-10 rounded-full bg-indigo-500" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">
              {{ getUser.attributes.sub }}
            </div>
            <div class="text-sm font-medium text-gray-500">
              {{ getUser.attributes.email }}
            </div>
          </div>
          <!--<button
            type="button"
            class="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">View notifications</span>
            <bell-icon
              class="h-6 w-6"
              aria-hidden="true"
            />
          </button>-->
        </div>
        <div
          v-if="signedIn"
          class="mt-3 space-y-1"
        >
          <DisclosureButton
            v-for="item in userNavigation"
            :key="item.name"
            as="router-link"
            :to="item.href"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
          >
            {{ item.name }}
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            @click="signOut"
          >
            Sign out
          </DisclosureButton>
        </div>
        <div
          v-else
          class="mt-3 space-y-1"
        >
          <DisclosureButton
            as="router-link"
            to="/sign-in"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
          >
            Sign in
          </DisclosureButton>
        </div>
      </div>
    </disclosure-panel>
  </disclosure>
</template>

<script>
import { mapGetters } from 'vuex'

import { Auth } from 'aws-amplify'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu as MenuWrapper, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  // BellIcon,
  MenuIcon,
  XIcon
} from '@heroicons/vue/outline'

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com'
}
const navigation = [
  // { name: 'Dashboard', href: '/', current: true }
  // { name: 'Team', href: '#', current: false },
  // { name: 'Projects', href: '#', current: false },
  // { name: 'Calendar', href: '#', current: false }
]
const userNavigation = [
  { name: 'Account', href: '/account' }
  // { name: 'Settings', href: '#' },
  // { name: 'Sign out', href: '#' }
]

export default {
  name: 'AppHeader',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuWrapper,
    MenuButton,
    MenuItem,
    MenuItems,
    // BellIcon,
    MenuIcon,
    XIcon
  },
  setup () {
    return {
      user,
      navigation,
      userNavigation
    }
  },
  computed: {
    ...mapGetters([
      'signedIn',
      'getUser'
    ])
  },
  methods: {
    signOut () {
      Auth.signOut()
    }
  }
}
</script>
